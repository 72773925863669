import React from "react"

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>Copyright © 2022 AKM Software Ltd. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
