import { useRef, useContext, useEffect } from "react"
import { useOnScreen } from "./useOnScreen"
import { NavMenuContext } from "../context/NavMenuContext"
/*eslint no-restricted-globals: ["error", "event", "fdescribe"]*/

export const useNavMenu = navMenuLinkID => {
  const ref = useRef(null)

  const { setActiveLink } = useContext(NavMenuContext)

  const isOnScreen = useOnScreen(ref)

  useEffect(() => {
    if (isOnScreen) {
      setActiveLink(navMenuLinkID)

      if (history.pushState) {
        history.pushState(null, null, `#${navMenuLinkID}`)
      } else {
        location.hash = `#${navMenuLinkID}`
      }
    }
  }, [isOnScreen, setActiveLink, navMenuLinkID])

  return ref
}
