import React from "react"
import { useNavMenu } from "../hooks/useNav"

export default function PreHeader() {
  const homeRef = useNavMenu("home")

  return (
    <>
      <div className="pre-header" id="home" ref={homeRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-sm-8 col-7">
              <ul className="info">
                {/* <li><a href="email:"><i class="fa fa-envelope"></i></a></li>
      <li><a href="tel:"><i class="fa fa-phone"></i></a></li> */}
              </ul>
            </div>
            <div className="col-lg-4 col-sm-4 col-5">
              <ul className="social-media">
                {/* <li><a href="#"><i class="fa fa-facebook"></i></a></li>
      <li><a href="#"><i class="fa fa-behance"></i></a></li>
      <li><a href="#"><i class="fa fa-twitter"></i></a></li>
      <li><a href="#"><i class="fa fa-dribbble"></i></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
