import { useState, useEffect, useRef } from "react"

export const useOnScreen = ref => {
  const [isOnScreen, setOnScreen] = useState(false)

  let observer = null

  useEffect(() => {
    observer = new IntersectionObserver(
      ([entry]) => setOnScreen(entry.isIntersecting),
      {
        threshold: [0.25, 0.5, 0.75],
      }
    )

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return isOnScreen
}
