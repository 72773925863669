import React, { useState } from "react"

export const NavMenuContext = React.createContext()

export const NavMenuProvider = ({ children }) => {
  const [activeLink, setActiveLink] = useState("")

  const providerValue = {
    activeLink,
    setActiveLink,
  }

  return (
    <NavMenuContext.Provider value={providerValue}>
      {children}
    </NavMenuContext.Provider>
  )
}
