import React, { useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

// import { StaticImage } from "gatsby-plugin-image"
import Logo from "../../static/assets/logo2.svg"

import { NavMenuContext } from "../context/NavMenuContext"

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks
        }
      }
    }
  `)
  const [mobileActive, setMobileActive] = useState(false)

  const { activeLink } = useContext(NavMenuContext)

  return (
    <header className="header-area header-sticky">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
              {/* ***** Logo Start ***** */}
              <a href="/#home" className="logo" title="AKM Software LTD">
                <Logo alt="AKM Software LTD" width={150} height={50} />
              </a>
              {/* ***** Logo End ***** */}
              {/* ***** Menu Start ***** */}
              <ul
                className="nav"
                style={{
                  display: mobileActive === true ? "block" : "none",
                }}
              >
                {data.site.siteMetadata.menuLinks.map(linkName => {
                  const link = linkName.toLowerCase()
                  return (
                    <li className="scroll-to-section" key={link}>
                      <a
                        href={`/#${link}`}
                        className={`${activeLink === link ? "active" : ""}`}
                      >
                        {linkName}
                      </a>
                    </li>
                  )
                })}
                <li className="scroll-to-section">
                  <div className="border-first-button">
                    <a href="/#contact">Free Quote</a>
                  </div>
                </li>
              </ul>
              <a
                className={`menu-trigger ${
                  mobileActive === true ? "active" : ""
                }`}
                onClick={() => {
                  setMobileActive(!mobileActive)
                }}
              >
                <span>Menu</span>
              </a>
              {/* ***** Menu End ***** */}
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}
