import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import CookieConsent, { Cookies } from "react-cookie-consent"

import { Helmet } from "react-helmet"
import "../../static/assets/css/bootstrap.min.css"
// import "../../static/assets/css/fontawesome.css"
// import "../../static/assets/css/owl.css"
import "../../static/assets/css/main.css"

import Header from "./Header"
import PreHeader from "./PreHeader"
import Footer from "./Footer"
import { NavMenuProvider } from "../context/NavMenuContext"
export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
          author
          charset
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <meta charset={data.site.siteMetadata.charset} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="author" content={data.site.siteMetadata.author} />
      </Helmet>

      <NavMenuProvider>
        <PreHeader />
        <Header />
        {children}
        <Footer />
        {/* <CookieConsent
        location="bottom"
        buttonText="Sure man!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <span style={{ fontSize: "12px" }}>
          This website stores cookies on your computer. These cookies are used
          to collect information about how you interact with this website and
          allow us to remember you. We use this information in order to improve
          and customize your browsing experience and for analytics and metrics
          about our visitors on this website. If you decline, your information
          won’t be tracked when you visit this website. A single cookie will be
          used in your browser to remember your preference not to be tracked.
        </span>
      </CookieConsent> */}
      </NavMenuProvider>
    </>
  )
}
